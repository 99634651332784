import React, { useState, useEffect } from 'react';
import LoadingIndicator from './LoadingIndicator'; // Ensure this is correctly imported

const ICMResults = ({ results, isLoading, playerChips, payouts }) => {
    const [showToast, setShowToast] = useState(false);
    
    // Effect to hide toast after timeout
    useEffect(() => {
        let toastTimer;
        if (showToast) {
            toastTimer = setTimeout(() => {
                setShowToast(false);
            }, 3000); // Hide toast after 3 seconds
        }
        
        return () => {
            if (toastTimer) clearTimeout(toastTimer);
        };
    }, [showToast]);

    const formatCurrency = (amount) => {
        return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    const copyToClipboard = () => {
        // Create data columns for Excel paste
        let clipboardData = '';
        
        // Loop through each player
        for (let i = 0; i < playerChips.length; i++) {
            // Create a column for each player with their data in the requested order:
            // 1. Chips, 2. Player name, 3. ICM payout, 4. Tournament payout
            const chipCount = playerChips[i];
            const playerNumber = `Player ${i + 1}`;
            const icmPayout = formatCurrency(results[i]);
            const tournamentPayout = i < payouts.length ? formatCurrency(payouts[i]) : "0.00";
            
            // Append player data as a column in the requested order
            clipboardData += `${chipCount}\t${playerNumber}\t${icmPayout}\t${tournamentPayout}\n`;
        }
        
        // Copy to clipboard
        navigator.clipboard.writeText(clipboardData)
            .then(() => {
                setShowToast(true); // Show toast notification instead of alert
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
                setShowToast(true); // Still show toast but could add an error state if needed
            });
    };

    return (
        <div className="icm-results">
            <div className='icm-payout-header'>
                <h2>Payouts:</h2>
                {results.length > 0 && !isLoading && (
                    <button 
                        onClick={copyToClipboard} 
                        className="copy-button" 
                        title="Copy to clipboard"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                        </svg>
                    </button>
                )}
            </div>
            <div className='icm-payout'>
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    results.map((result, index) => (
                        <div key={index} className="payout-line">
                            <span className="player-label">Player {index + 1}:</span>
                            <span className="payout-amount">${formatCurrency(result)}</span>
                        </div>
                    ))
                )}
            </div>
            
            {/* Toast notification */}
            {showToast && (
                <div className="toast-notification">
                    <div className="toast-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <span>Results copied to clipboard!</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ICMResults;
